import React from 'react'
import Link from 'gatsby-link'
// import Kenneth from '../img/kennethau_profile_low.jpg'
import Footer from '../components/footer'
import Layout from '../layouts/index'
import {
  FaCodeBranch,
  FaAws,
  FaNode,
  FaCode,
  FaWordpress,
  FaReact,
  FaShoppingCart,
} from 'react-icons/fa'

const Home = () => (
  <Layout>
    <div className="home">
      <section className="section hero is-fullheight section-kenneth is-half">
        <div className="hero-body">
          <div className="container has-text-right">
            <div className="columns">
              <div className="column" />
              <div className="column is-5">
                <h2 className="subtitle">Hi</h2>
                <h1 className="title is-1">I'm Kenneth</h1>
                <h2 className="subtitle is-3"> Software Engineer</h2>
                <Link
                  to="/about"
                  className="button is-primary is-rounded is-medium"
                >
                  About Me
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-details">
        <div className="container" id="headline-features">
          <div className="columns feature is-centered">
            <div className="column is-3">
              <div className="card">
                <div className="card-content">
                  <div className="content">
                    <FaCode size="1.2em" />
                    <span className="home--skills">Web Design</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="column feature is-3">
              <div className="card">
                <div className="card-content">
                  <div className="content">
                    <FaReact size="1.2em" />
                    <span className="home--skills">React</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="column feature is-3">
              <div className="card">
                <div className="card-content">
                  <div className="content">
                    <FaWordpress size="1.2em" />
                    <span className="home--skills">Wordpress</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="columns feature is-centered">
            <div className="column is-3">
              <div className="card">
                <div className="card-content">
                  <div className="content">
                    <FaAws size="1.2em" />
                    <span className="home--skills">AWS Lambda</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="column is-3">
              <div className="card">
                <div className="card-content">
                  <div className="content">
                    <FaNode size="1.2em" />
                    <span className="home--skills">Node JS</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="column is-3">
              <div className="card">
                <div className="card-content">
                  <div className="content">
                    <FaShoppingCart size="1.2em" />
                    <span className="home--skills">Shopify</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section-quote">
            <div className="container" id="headline-features">
              <div className="columns is-centered">
                <div className="column is-half ">
                  <p>It's not about the idea.</p>
                  <hr />
                  <h2>It's about making ideas happen.</h2>
                </div>
              </div>
            </div>
          </div>

          <div className=" has-text-centered">
            <a
              href="http://www.github.com/kennly"
              className="button is-primary is-rounded is-medium "
            >
              View My Github
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  </Layout>
)

export default Home
